import moment from 'moment-timezone';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { DebugFieldComponent, DebugItemsComponent, DebugSizeComponent } from '../../ui';
import { RestaurantTableService } from '../../../../services';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'core-debug-table',
  standalone: true,
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugSizeComponent,
    DebugItemsComponent,
    DebugFieldComponent,
    UpperCasePipe,
  ],
})
export class TableComponent {

  public readonly table = toSignal(this.restaurantTable.table$);

  constructor(
    private readonly restaurantTable: RestaurantTableService,
  ) {}

  public dayName(day: number): string {
    return moment().weekday(day).format('dddd');
  }

}
