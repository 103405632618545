import moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { BehaviorSubject, concat, Observable, of, timer } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, tap, toArray } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined } from '../utils';
import { HappyHoursModel, HappyHoursNextModel, HappyHoursNowModel, IHappyHours, IHappyHoursRaw } from '../models';
import { AuthService } from './auth.service';
import { HappyHoursStorageService } from './happy-hours.storage.service';
import { WatchdogService } from './watchdog.service';

@UntilDestroy()
@Injectable()
export class HappyHoursService {

  public readonly timetable$ = new BehaviorSubject<IHappyHours[]>([]);
  public readonly status$ = new BehaviorSubject<HappyHoursNextModel | HappyHoursNowModel | null>(null);
  private readonly logger = this.watchdog.tag('Happy Hours Service', 'green');

  constructor(
    private readonly watchdog: WatchdogService,
    private readonly auth: AuthService,
    private readonly storage: HappyHoursStorageService,
  ) {
    this.initTimetable();
    this.initLogouted();
    this.initStatus();
  }

  get isNow$(): Observable<boolean> {
    return this.status$.pipe(
      map((hh) => hh instanceof HappyHoursNowModel),
      distinctUntilChanged(),
    );
  }

  public gatAll(): Observable<IHappyHours[]> {
    return this.storage.getAll();
  }

  public updateByCollection(timetable: IHappyHoursRaw[]): Observable<HappyHoursModel[]> {
    return this.gatAll().pipe(
      filter((value) => {
        const newValue = timetable.map(HappyHoursModel.fromRaw);

        return JSON.stringify(value) !== JSON.stringify(newValue);
      }),
      switchMap(() => this.storage.clear()),
      switchMap(() =>
        concat(
          ...timetable.map((hh) => this.storage.set(HappyHoursModel.fromRaw(hh)).pipe(
            filter(isDefined),
          )),
        ).pipe(
          toArray(),
        ),
      ),
      tap((value) => this.timetable$.next(value)),
    );
  }

  public clear(): Observable<boolean> {
    return this.storage.clear().pipe(
      tap(() => this.timetable$.next([])),
    );
  }

  private initTimetable(): void {
    this.gatAll().pipe(
      untilDestroyed(this),
    ).subscribe((timetable) => {
      this.logger.info('Happy hours initialized', timetable);
      this.timetable$.next(timetable);
    });
  }

  private initLogouted(): void {
    this.auth.logouted$.pipe(
      switchMap(() => this.clear()),
      tap(() => this.logger.info('Cleared happy hours on logout')),
      untilDestroyed(this),
    ).subscribe();
  }

  private initStatus(): void {
    this.timetable$.pipe(
      tap(() => this.logger.info('Happy hours started to watch')),
      switchMap((times) => {
        if (times.length === 0) {
          return of(null);
        }

        return this.atHappyHour(times);
      }),
      untilDestroyed(this),
    ).subscribe((status) => {
      this.logger.debug('Happy hours status changed', status ? status : 'No happy hours now');
      this.status$.next(status);
    });
  }

  private atHappyHour(happyHours: IHappyHours[]): Observable<HappyHoursNextModel | HappyHoursNowModel | null> {
    return timer(0, 1000).pipe(
      map(() => {
        const currentDate: Date = new Date();
        const [day, currentHours] = [
          currentDate.getDay(),
          currentDate.getHours(),
          currentDate.getMinutes(),
        ];

        return happyHours.filter((hh) => hh.day === day && currentHours <= hh.timeTo.hours);
      }),
      map((candidates) => {
        const happyHourNow = candidates.find((hh) => {
          const currentDate = moment();
          const dateFrom = HappyHoursModel.hhTimeToDate(hh.timeFrom);
          const dateTo = HappyHoursModel.hhTimeToDate(hh.timeTo);

          return currentDate.isSameOrAfter(dateFrom) && currentDate.isSameOrBefore(dateTo);
        });

        if (happyHourNow) {
          return new HappyHoursNowModel(happyHourNow);
        }

        const happyHourNext = candidates.find((hh) => {
          const currentDate = moment();
          const dateFrom = HappyHoursModel.hhTimeToDate(hh.timeFrom);
          const hoursBeforeDateFrom = dateFrom.clone().subtract(60, 'minutes');

          return currentDate.isSameOrAfter(hoursBeforeDateFrom) && currentDate.isBefore(dateFrom);
        });

        if (happyHourNext) {
          return new HappyHoursNextModel(happyHourNext);
        }

        return null;
      }),
    );
  }
}
