import { ChangeDetectionStrategy, Component, Inject, signal } from '@angular/core';
import { CORE_APP_ENV, CORE_APP_NAME, CORE_APP_VERSION } from '../../../core.tokens';
import { DebugCardComponent } from './ui';
import { DebugAppComponent, DebugDeviceComponent, TableComponent } from './stats';
import { RestaurantTableService, UpdateService } from '../../services';
import { AsyncPipe } from '@angular/common';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'core-debug',
  standalone: true,
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DebugCardComponent,
    DebugAppComponent,
    TableComponent,
    DebugDeviceComponent,
    AsyncPipe,
  ],
})
export class DebugComponent {

  public readonly open = signal<'app' | 'table' | null>(null);

  public readonly updateEnabled = this.update.enabled;
  public readonly updateStatus = toSignal(this.update.status$);
  public readonly table = toSignal(this.restaurant.table$);

  constructor(
    @Inject(CORE_APP_NAME) public readonly appName: string,
    @Inject(CORE_APP_ENV) public readonly appEnv: string,
    @Inject(CORE_APP_VERSION) public readonly appVersion: string,
    private readonly update: UpdateService,
    private readonly restaurant: RestaurantTableService,
  ) {}

  public handleToggleApp(event: Event): void {
    event.preventDefault();
    this.open.set(this.open() === 'app' ? null : 'app');
  }

  public handleToggleTable(event: Event): void {
    event.preventDefault();
    this.open.set(this.open() === 'table' ? null : 'table');
  }

  public handleCheckForUpdate(event: Event): void {
    event.preventDefault();
    this.update.check().subscribe();
  }

  public handleUpdate(event: Event): void {
    event.preventDefault();
    this.update.restartImmediately().subscribe();
  }

  public handleReload(event: Event): void {
    event.preventDefault();
    window.location.reload();
  }

}
