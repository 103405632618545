import moment from 'moment-timezone';
import { Duration, Moment } from 'moment';

export interface IHappyHoursRaw {
  day: number;
  timeFrom: string;
  timeTo: string;
}

export interface IHappyHoursTime {
  hours: number;
  minutes: number;
}

export interface IHappyHours {
  day: number;
  timeFrom: IHappyHoursTime;
  timeTo: IHappyHoursTime;
}

export class HappyHoursModel implements IHappyHours {
  public day: number;
  public timeFrom: IHappyHoursTime;
  public timeTo: IHappyHoursTime;

  constructor(hh: IHappyHours) {
    this.day = hh.day;
    this.timeFrom = hh.timeFrom;
    this.timeTo = hh.timeTo;
  }

  public get timeStart(): Moment {
    return HappyHoursModel.hhTimeToDate(this.timeFrom);
  }

  public get timeEnd(): Moment {
    return HappyHoursModel.hhTimeToDate(this.timeTo);
  }

  public get timeLeftBeforeStart(): Duration {
    const nowDate = moment();
    const startDate = moment(this.timeStart);

    return moment.duration(startDate.diff(nowDate, 'seconds'), 'seconds');
  }

  public get timeLeftBeforeEnd(): Duration {
    const nowDate = moment();
    const startEnd = moment(this.timeEnd);

    return moment.duration(startEnd.diff(nowDate, 'seconds'), 'seconds');
  }

  public static fromRaw(hhr: IHappyHoursRaw): HappyHoursModel {
    const from = moment(hhr.timeFrom, 'HH:mm:ss');
    const to = moment(hhr.timeTo, 'HH:mm:ss');

    return new HappyHoursModel({
      day: hhr.day,
      timeFrom: { hours: from.hours(), minutes: from.minutes() },
      timeTo: { hours: to.hours(), minutes: to.minutes() },
    });
  }

  public static hhTimeToDate(time: IHappyHoursTime): Moment {
    const date = moment();
    date.set({
      hours: time.hours,
      minutes: time.minutes,
      seconds: 0,
    });

    return date;
  }
}
