import { APP_INITIALIZER, PLATFORM_ID } from '@angular/core';
import {
  CORE_APP_ENV,
  CORE_APP_NAME,
  CORE_APP_VERSION,
  CORE_DEBUG,
  CORE_FEATURE_TOGGLE,
  CORE_STARTUP_DATE,
  CORE_STARTUP_ID,
} from './core.tokens';
import {
  DWallIntercom,
  InteractionService,
  NetworkService,
  OrchestratorService,
  UpdateService,
  WatchdogService,
  WebsocketService,
} from './lib/services';

export const provideCoreInitialize = () => (
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (
      id: string,
      date: Date,
      name: string,
      version: string,
      environment: string,
      debug: boolean,
      featureToggle: unknown,
      platformId: unknown,
      update: UpdateService,
      network: NetworkService,
      orchestrator: OrchestratorService,
      interaction: InteractionService,
      websocket: WebsocketService,
      intercom: DWallIntercom,
      watchdog: WatchdogService,
    ) => () => {
      const log = watchdog.tag('App', 'red');

      log.info('Initializing', {
        id,
        date,
        name,
        version,
        environment,
        debug,
        featureToggle,
        platform: platformId,
      });

      orchestrator.initialize();
      update.initialize();
      network.initialize();
      interaction.initialize();
      intercom.initialize();
      websocket.initialize();
    },
    deps: [
      CORE_STARTUP_ID,
      CORE_STARTUP_DATE,
      CORE_APP_NAME,
      CORE_APP_VERSION,
      CORE_APP_ENV,
      CORE_DEBUG,
      CORE_FEATURE_TOGGLE,
      PLATFORM_ID,
      UpdateService,
      NetworkService,
      OrchestratorService,
      InteractionService,
      DWallIntercom,
      WebsocketService,
      WatchdogService,
    ],
  }
);
