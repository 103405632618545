import { DestroyRef, Inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import { WatchdogService } from './watchdog.service';
import { CORE_STARTUP_ID } from '../../core.tokens';

type OrchestratorMessageTypes = {
  relay: {
    type: string;
  },
}

type OrchestratorMessageType = keyof OrchestratorMessageTypes;
type OrchestratorMessageData<T extends OrchestratorMessageType> = OrchestratorMessageTypes[T];

type OrchestratorMessage<T extends keyof OrchestratorMessageTypes> = {
  emitter: string;
  type: T;
  data: OrchestratorMessageData<T>;
};

type OrchestratorMessageAny = OrchestratorMessage<OrchestratorMessageType>;

@Injectable()
export class OrchestratorService {

  private readonly logger = this.watchdog.tag('Orchestrator', 'red');
  private readonly channel = new BroadcastChannel<OrchestratorMessageAny>('orchestrator');
  private readonly elector = createLeaderElection(this.channel);

  public readonly leader$ = fromPromise(this.elector.awaitLeadership());
  public readonly messages$ = fromEvent(this.channel, 'message');

  constructor(
    @Inject(CORE_STARTUP_ID) private readonly id: string,
    private readonly destroyRef: DestroyRef,
    private readonly watchdog: WatchdogService,
  ) {}

  public initialize(): void {
    this.logger.info('Initializing');

    this.leader$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.logger.info('Leader');
    });

    this.messages$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((message) => {
      this.logger.info('Received', message);
    });
  }

  public broadcast<T extends OrchestratorMessageType>(type: T, data: OrchestratorMessageData<T>): Promise<void> {
    return this.channel.postMessage({
      emitter: this.id,
      type,
      data,
    });
  }

}
