import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { isUndefined } from '../utils';
import { HappyHoursModel, IHappyHours } from '../models';
import { WatchdogService } from './watchdog.service';

@Injectable()
export class HappyHoursStorageService {

  private readonly collectionName = 'happyHours';

  private readonly logger = this.watchdog.tag('Happy Hours Storage', 'blue');

  public constructor(
    private readonly db: NgxIndexedDBService,
    private readonly watchdog: WatchdogService,
  ) {
    this.get(0).subscribe();
  }

  public set(value: IHappyHours): Observable<HappyHoursModel> {
    return this.db.update(this.collectionName, value).pipe(
      map(entry => new HappyHoursModel(entry)),
      tap({
        next: (entry) => this.logger.debug('Happy hours updated', entry),
        error: (error) => this.logger.error('Failed to update happy hours', value, error),
      }),
    );
  }

  public get(day: number): Observable<HappyHoursModel | undefined> {
    return this.db.getByKey<IHappyHours | undefined>(this.collectionName, day).pipe(
      map(entry => {
        if (isUndefined(entry)) {
          return undefined;
        }

        return new HappyHoursModel(entry);
      }),
      tap({
        next: (entry) => {
          if (entry) {
            this.logger.debug('Happy hours loaded', entry);
          }
          else {
            this.logger.warn('Happy hours not found', day);
          }
        },
        error: (error) => this.logger.error('Failed to load happy hours', day, error),
      }),
    );
  }

  public getAll(): Observable<IHappyHours[]> {
    return this.db.getAll<IHappyHours>(this.collectionName).pipe(
      map((entries) => entries.map((entry) => new HappyHoursModel(entry))),
      tap({
        next: (entries) => this.logger.debug('Happy hours all loaded', entries),
        error: (error) => this.logger.error('Failed to load all happy hours', error),
      }),
    );
  }

  public clear(): Observable<boolean> {
    return this.db.clear(this.collectionName);
  }

}
